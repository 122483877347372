<template>
    <Layout>
        <Content :style="{'padding': '50px'}">
                <Breadcrumb :style="{margin: '20px 0'}">
                    <BreadcrumbItem>角色管理</BreadcrumbItem>
                    <BreadcrumbItem>角色权限</BreadcrumbItem>
                </Breadcrumb>
                
                <Card>
                    <!-- <p :style="{'margin-bottom':'5px'}">
                    应用:<Select v-model="appid" style="width:200px" @on-change="getResourceList">
                        <Option v-for="item in this.app_list" :value="item.id" :key="item.id">{{item.name}}</Option>
                    </Select> -->

                    </p>
                   <Table :columns="tableColumns" :data="resource_list">
                       <template slot-scope="{ row, index }" slot="action">
                        <Checkbox v-for="item in row.action_list" :label="item" :key="index+item" @on-change="chooseRoleAction($event,row.id,item)" v-model="role_action_list[row.id+'-'+item]">{{action_name[item]}}</Checkbox>
                        </template>
                   </Table>
                   <Page :total="recordTotal" :current="page" :page-size="100" @on-change="getResourceList"/>
                </Card>
        </Content>
       
      
    </Layout>
    
</template>
<script>
export default {
    data(){
        return {
            showmodal:false,
            uploadHeader :{
              "Authorization":localStorage.getItem('token')
            },
            appid:1,
            role_id:this.$route.params.id,
            role:{},
            resource:{
                id:0,
                appid:'',
                name:'',
                symbol:'',
                action_list:[]
            },
            recordTotal:0,
            page:1,
            resource_list:[],
            search_resource_list:[],
            search_loading:false,
            app_list:[],
            action_name:{},
            role_action_list:[],
            tableColumns:[
                {
                    title: 'ID',
                    key: 'id'
                },
                {
                    title: '资源名',
                    key: 'name'
                },    
                {
                    title: '操作',
                    slot: 'action',
                    align: 'center',    
                }
            ],
            "rows":[]
        }
    },
    mounted:function(){
        async function init(app){
        //    await app.getAppList(1);
           await app.getActionList();
           await app.getRole();

          
        }
        init(this);
        
    },
    computed:{
      getUploadUrl(){
        return process.env.VUE_APP_APIHOST+'/api/util/imgUpload';
      },
      checkResourceAction(resourceId,action)
        {
            if(typeof(this.role_action_list)=="undefined")
            {
                return false;
            }
            else if(typeof(this.role_action_list[resourceId+'-'+action])=="undefined")
            {
                return false;
            }
           
            return true;
                
        }
    },
    methods:{
        getRole()
        {
            this.http.get('/api/role/roleinfo',{params:{'id':this.role_id}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                   this.$Message.error(data.message);
                }
                else{
                    this.role=data.data;
                    this.appid=this.role.appid;
                    this.getResourceList(1);
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        getResourceCount(){
            this.http.get('/api/resource/getresourcecount',{params:{'appid':this.appid}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                   this.$Message.error(data.message);
                }
                else{
                    this.recordTotal=data.data;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        getResourceList(page=1){
            this.http.get('/api/resource/getResourceWithAction',{params:{'page':page,'appid':this.appid}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.resource_list=data.data.list;
                    for(let i in this.resource_list)
                    {
                        let item=this.resource_list[i];
                        // this.role_action_list[item.id]={};
                        for(let j in item.action_list)
                        {
                            let action=item.action_list[j];
                            // this.role_action_list[item.id+'-'+action]= false;
                            this.$set(this.role_action_list,item.id+'-'+action,false);

                        }
                        
                    }

                }
                this.getRoleActionList()
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
            this.getResourceCount();
        },

        getRoleActionList(){
            this.http.get('/api/role/getroleactionlist',{params:{'role_id':this.role_id}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    for(let i in data.data)
                    {
                        let item=data.data[i];
                        // this.role_action_list[item.resource_id+'-'+item.action]=true;
                        // let obj={};
                        // obj[item.action]=true;
                        this.$set(this.role_action_list,item.resource_id+'-'+item.action,true);
                    }
                    console.log(this.role_action_list);
                }
            }).catch((e)=>{
                console.log(e);
                this.$Message.error('网络错误');
            });
        },
        // getAppList(page=1){
        //     this.http.get('/api/applist/getapplist',{params:{'page':page,}}).then(response=>{
        //         let data=response.data;
        //         if(data.code!==0)
        //         {
        //             this.$Message.error(data.message);
        //         }
        //         else{
        //             this.app_list=data.data.list;
        //             this.appid=this.app_list[0]['id'];
        //         }
        //     }).catch(()=>{
        //         this.$Message.error('网络错误');
        //     });
        // },
        searchResource(name)
        {
            this.search_loading=true
            this.http.get('/api/resource/searchresource',{params:{'appid':this.appid,'name':name}}).then(response=>{
                let data=response.data;
                this.search_loading=false
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.search_resource_list=data.data.list;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
         getActionList(){
            this.http.get('/api/action/getactionlist',{params:{}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    let list=data.data.list;
                    for(let idx in list)
                    {
                        this.action_name[list[idx]['symbol']]=list[idx]['name'];
                    }
                   
                    
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        chooseRoleAction($event,resourceId,action){
            let roleId=this.role_id;
            if($event==true)
            {
                this.http.post('/api/role/addroleaction',{"role_id":roleId,"resource_id":resourceId,"action":action}).then(response=>{
                    let data=response.data;
                    if(data.code!==0)
                    {
                        this.$Message.error(data.message);
                    }
             
                }).catch(()=>{
                    this.$Message.error('网络错误');
                });
            }
            else
            {
                this.http.post('/api/role/delroleaction',{"role_id":roleId,"resource_id":resourceId,"action":action}).then(response=>{
                    let data=response.data;
                    if(data.code!==0)
                    {
                        this.$Message.error(data.message);
                    }
             
                }).catch(()=>{
                    this.$Message.error('网络错误');
                });
            }
        }
    }
    
}
</script>
