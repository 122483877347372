import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  routes: [
    { path: '/login', name:'login', component: () => import('@/views/Login') },
    { path: '/updatepwd', name:'updatepwd', component: () => import('@/views/UpdatePwd') },
    {
        path: '/',
        component: () => import('@/views/Layout'),
        redirect: '/app/list',
        children: [
          {
            path: 'app/list',
            component: () => import('@/views/app/List'),

          },
          {
            path: 'user/list',
            component: () => import('@/views/user/List'),

          },
          {
            path: 'user/userrole/:uid',
            component: () => import('@/views/user/UserRole'),

          },
          {
            path: 'role/list',
            component: () => import('@/views/role/List'),

          },
          {
            path: 'role/resource/:id',
            component: () => import('@/views/role/Resource'),

          },
          {
            path: 'resource/list',
            component: () => import('@/views/resource/List'),

          },
          {
            path: 'resource/action/:id',
            component: () => import('@/views/resource/Action'),

          },
          {
            path: 'action/list',
            component: () => import('@/views/action/List'),

          },
          {
            path: 'log/list',
            component: () => import('@/views/log/List'),

          },
        
          { path: '*', redirect: '/app/list' }
          ]
    }
  ]
  
})

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem('token');
    if(token || to.name=='login') 
    { // 获取当前的 token 是否存在
      next()
    } 
    else 
    {
      // 不存在 token，需要重新认证
      next({
        path: '/login'
      })
    }
    next()
  
})

export default router
