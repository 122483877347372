<template>
    <Layout>
        <Content :style="{'padding': '50px'}">
                <Breadcrumb :style="{margin: '20px 0'}">
                    <BreadcrumbItem>操作日志查询</BreadcrumbItem>
                </Breadcrumb>
                
                <Card>
                    <p :style="{'margin-bottom':'5px'}">
                       应用： <Select v-model="appid" :style="{'width':'200px'}">
                           <Option value="0">全部</Option>
                           <Option v-for="item in app_list" :value="item.id" :key="item.id">{{item.name}}</Option>
                       </Select>
                        用户名：<Input v-model="username" :style="{'width':'200px'}"/>
                       <Button type='primary' @click="searchLog()" :style="{'margin-left':'20px'}">查询</Button>
                    </p>
                   <Table :columns="tableColumns" :data="log_list">
                       <template slot-scope="{row, index}" slot="appid">
                           {{app_name_map[row.appid]}}
                       </template>
                   </Table>
                  <Page :total="logcount" :current="page" :page-size="50" @on-change="getLogList"/>

                </Card>
        </Content>
     
      
    </Layout>
    
</template>
<script>
export default {
    data(){
        return {
            showmodal:false,
            uploadHeader :{
              "Authorization":localStorage.getItem('token')
            },
            appid:0,
            app_list:[],
            app_name_map:{},
            logcount:0,
            log_list:[],
            page:1,
            username:'',
            tableColumns:[
                {
                    title: 'ID',
                    key: 'id'
                },
                {
                    title: '应用',
                    slot: 'appid'

                },
                {
                    title: '用户名',
                    key: 'username'
                },
                {
                    title: '行为',
                    key: 'action_name'
                },
                {
                    title: 'IP',
                    key: 'ip'
                },
                {
                    title: '参数',
                    key: 'param_data'
                },
                {
                    title: '操作结果',
                    key: 'result'
                },
                {
                    title: '时间',
                    key: 'created_at'
                },
               
                
            ],
            "rows":[]
        }
    },
    mounted:function(){
        this.getAppList();
       
    },
    computed:{
      getUploadUrl(){
        return process.env.VUE_APP_APIHOST+'/api/util/imgUpload';
      }
    },
    methods:{
        searchLog(){
            this.getLogList(1);
            this.getLogCount();
        },
        getLogList(page){
            this.http.get('/api/log/list',{params:{appid:this.appid,username:this.username,page:page}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.log_list=data.data.list;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
         getLogCount(){
            this.http.get('/api/log/count',{params:{appid:this.appid,username:this.username}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.logcount=parseInt(data.data);
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
         getAppList(){
            this.http.get('/api/applist/getapplist',{params:{'page':1,}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.app_list=data.data.list;
                    for(let i in this.app_list)
                    {
                        this.app_name_map[this.app_list[i]['id']]=this.app_list[i]['name']
                    }
                }
                 this.getLogList(1);
                this.getLogCount();
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        
    }
    
}
</script>
