<template>
    <Layout>
        <Content :style="{'padding': '50px'}">
                <Breadcrumb :style="{margin: '20px 0'}">
                    <BreadcrumbItem>资源管理</BreadcrumbItem>
                </Breadcrumb>
                
                <Card>
                    <p :style="{'margin-bottom':'5px'}">
                    应用:<Select v-model="appid" style="width:200px" @on-change=getResourceList(1)>
                        <Option v-for="item in this.app_list" :value="item.id" :key="item.id">{{item.name}}</Option>
                    </Select>

                       <Button type='primary' @click="showTypeModal(0)" :style="{'margin-left':'20px'}">新增资源</Button>
                    </p>
                   <Table :columns="tableColumns" :data="resource_list"></Table>
                   <Page :total="recordTotal" :current="page" :page-size="100" @on-change="getResourceList"/>
                </Card>
        </Content>
        <Modal
            v-model="showmodal"
            :footer-hide=true
            title="新建/编辑资源">
            <Form ref="resource" :model="resource" :label-width="80" :rules="rulesTag">
                <FormItem label="应用" prop="appid">
                    <Select v-model="resource.appid" style="width:200px">
                        <Option v-for="item in this.app_list" :value="item.id" :key="item.id">{{item.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="资源名" prop="name">
                    <Input v-model="resource.name"/>
                </FormItem>
                <FormItem label="上级资源" prop="parent_id">
                    <Select v-model="resource.parent_id"  :remote-method="searchResource" filterable :loading="search_loading">
                        <Option v-for="item in this.search_resource_list" :value="item.id" :key="item.id">{{item.name}}</Option>
                    </Select>
                    
                </FormItem>
                <FormItem label="标识" prop="symbol">
                    <Input v-model="resource.symbol"/>
                </FormItem>
             
             
                <FormItem>
                    <Button type="primary" @click="handleSubmit()">提交</Button>
                </FormItem>
            </Form>
        </Modal>
      
    </Layout>
    
</template>
<script>
export default {
    data(){
        return {
            showmodal:false,
            uploadHeader :{
              "Authorization":localStorage.getItem('token')
            },
            appid:0,
            resource:{
                id:0,
                appid:'',
                name:'',
                symbol:'',
            },
            recordTotal:0,
            page:1,
            resource_list:[],
            search_resource_list:[],
            search_loading:false,
            app_list:[],

            rulesTag:{
                    appid: [
                        { required: true, message: '应用不能为空',trigger: 'blur',type:"string" }
                    ],
                    name: [
                        { required: true, message: '名称不能为空',trigger: 'blur',type:"string" },
                    ],
                    symbol: [
                        { required: true, message: '标识不能为空',trigger: 'blur',type:"string" },
                    ],
               
            },
            tableColumns:[
                {
                    title: 'ID',
                    key: 'id'
                },
                {
                    title: '资源名',
                    key: 'name'
                },
                {
                    title: '标识',
                    key: 'symbol'
                },
               
               
                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Button', {
                                props: {
                                    type: 'primary',
                                    size: 'small'
                                },
                                style: {
                                        marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                       this.$router.push('/resource/action/'+params.row.id)
                                    }
                                }
                            }, '资源操作'),
                            h('Button', {
                                props: {
                                    type: 'primary',
                                    size: 'small'
                                },
                                style: {
                                        marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                       this.resource.id=params.row.id;
                                       this.resource.appid=params.row.appid;
                                       this.resource.name=params.row.name;
                                       this.resource.symbol=params.row.symbol;
                                    
                                       this.showTypeModal(1);
                                    }
                                }
                            }, '编辑'),
                           
                            h('Button', {
                                props: {
                                    type: 'error',
                                    size: 'small'
                                },
                                style: {
                                        marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                       this.delResource(params.row.id,params.index)
                                    }
                                }
                            }, '删除'),
                           
                            
                        ]);
                    }
                }
            ],
            "rows":[]
        }
    },
    mounted:function(){
        this.getAppList(1);


    },
    computed:{
      getUploadUrl(){
        return process.env.VUE_APP_APIHOST+'/api/util/imgUpload';
      }
    },
    methods:{

        showTypeModal(edit){
            if(edit==0)
            {
                this.resource.id='';
                this.resource.appid='';
                this.resource.name='';
                this.resource.symbol='';
                this.resource.parent_id=0;
            }
            this.showmodal=true;
        },
        handleSubmit(){
            this.$refs["resource"].validate((valid) => {
                if (valid) {
                    if(this.resource.id > 0)
                    {
                        this.http.post('/api/resource/updateresource',this.resource).then(response=>{
                            let data=response.data;
                            if(data.code!==0)
                            {
                                this.$Message.error(data.message);
                                return false;
                            }
                            else{
                                this.showmodal=false;
                                
                                this.getResourceList(this.page);
                                return true;
                            }
                        });
                        
                    }
                    else
                    {
                        this.http.post('/api/resource/createresource',this.resource).then(response=>{
                            let data=response.data;
                            if(data.code!==0)
                            {
                                this.$Message.error(data.message);
                                return false;
                            }
                            else{
                                this.resource.id='';
                                this.resource.appid='';
                                this.resource.name='';
                                this.resource.symbol='';
                                this.showmodal=false;
                                this.getResourceList(1);
                                return true;
                            }
                        });
                    }
                    
                } else {
                    this.$Message.error('表单验证未通过');
                }
            });
            return false;
        },
        getResourceCount(){
            this.http.get('/api/resource/getresourcecount',{params:{'appid':this.appid}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.recordTotal=data.data;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        getResourceList(page=1){
            this.http.get('/api/resource/getresourcelist',{params:{'page':page,'appid':this.appid}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.resource_list=data.data.list;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
            this.getResourceCount();
        },

        delResource(id,index){
            this.http.post('/api/resource/delresource',{'id':id,}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                    return false;
                }
                this.resource_list.splice(index,1);
                return true;
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        getAppList(page=1){
            this.http.get('/api/applist/getapplist',{params:{'page':page,}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.app_list=data.data.list;
                    this.appid=this.app_list[0].id;
                    this.getResourceList(1);
                    this.getResourceCount();
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        searchResource(name)
        {
            this.search_loading=true
            this.http.get('/api/resource/searchresource',{params:{'appid':this.appid,'name':name}}).then(response=>{
                let data=response.data;
                this.search_loading=false
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.search_resource_list=data.data.list;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        }
        
    }
    
}
</script>
