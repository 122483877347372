<template>
  <Layout>
        <Header>
            <span :style="{'float':'left','color':'white','font-size':'28px','margin-left':'-30px','font-weight':'bold'}">
                星轨-权限管理系统
            </span>
            <Menu mode="horizontal" theme="dark" active-name="1" :style="{'float':'right'}">

                    <template v-if="account!=''">
                        <MenuItem name="account">
                            {{account}}
                        </MenuItem>
                        <MenuItem name="updatepwd" to="/updatepwd">
                            修改密码
                        </MenuItem>
                        <Button @click="logout">
                            退出
                        </Button>
                    </template>
                    <template v-else>
                        <MenuItem name="tag" to="/login">
                        登录
                        </MenuItem>
                    </template>         
                    
               
            </Menu>
        </Header>
        <Layout>
            <Sider hide-trigger :style="{background: '#fff'}">
                <Menu theme="light">
                    <MenuItem name="applist" to="/app/list" v-show="menuShow('applist')">
                        <Icon type="ios-home"></Icon>应用管理
                    </MenuItem>
                    <MenuItem name="userlist" to="/user/list" v-show="menuShow('userlist')">
                        <Icon type="ios-body"></Icon>用户管理
                    </MenuItem>
                    <MenuItem name="rolelist" to="/role/list" v-show="menuShow('rolelist')">
                        <Icon type="ios-briefcase"></Icon>角色管理
                    </MenuItem>
                    <Submenu name='resource' v-show="menuShow('resource')">
                        <template slot="title">
                        <Icon type="logo-buffer"></Icon>资源管理
                        </template>
                        <MenuItem name="resourcelist" to="/resource/list">资源列表</MenuItem>
                        <MenuItem name="actionlist" to="/action/list" v-show="menuShow('actionlist')">操作列表</MenuItem>
                    </Submenu>
                    <MenuItem name="loglist" to="/log/list" v-show="menuShow('loglist')">
                        <Icon type="ios-briefcase"></Icon>操作日志
                    </MenuItem>
                    
                
                </Menu>
            </Sider>
            <router-view/>

        </Layout>
    </Layout>
</template>
<script>
export default {
    data(){
        return {
           account: localStorage.getItem('account'),
           action_auth: JSON.parse(localStorage.getItem('action_auth')),
           is_super: localStorage.getItem("is_super"),
        }
    },
    mounted:function(){
        // this.rolesRoot['none']=[];
        // this.getroles();
        // this.getmenus();
    },
    computed:{

    },
    methods:{
        menuShow(menuname)
        {
  
            switch(menuname)
            {
                case 'applist':
                    if(this.is_super==1 || typeof(this.action_auth['app'])!='undefined')
                    {
                        return true;
                    }
                    return false;
                case 'userlist':
                    if(this.is_super==1 || typeof(this.action_auth['user'])!='undefined'
                    || typeof(this.action_auth['user-role'])!='undefined')
                    {
                        return true;
                    }
                    return false;
                case 'rolelist':
                    if(this.is_super==1 || typeof(this.action_auth['role'])!='undefined'
                     || typeof(this.action_auth['role-resource'])!='undefined'
                    )
                    {
                        return true;
                    }
                    return false;
                case 'resource':
                    if(this.is_super==1 || typeof(this.action_auth['resource'])!='undefined'
                        || typeof(this.action_auth['action'])!='undefined'
                    )
                    {
                        return true;
                    }
                    return false;
                case 'actionlist':
                    if(this.is_super==1 || typeof(this.action_auth['action'])!='undefined')
                    {
                        return true;
                    }
                    return false;
                case 'loglist':
                    if(this.is_super==1 ||typeof(this.action_auth['log'])!='undefined')
                    {
                        return true;
                    }
                    return false;
                default:
                    return false;
            }
            
        },
        logout(){   
            // this.$store.commit('SET_ACCOUNT','');
            // this.$store.commit('SET_TOKEN','');
            // this.$store.commit('SET_ACTIONAUTH','')
            localStorage.removeItem('account');
            localStorage.removeItem('token');
            localStorage.removeItem('action_auth');
            localStorage.removeItem('is_super');
            clearInterval(this.timer);
            this.$router.push({'path':'/login'});            
        },
 

    },

}
</script>

