<template>
    <Layout>
        <Content :style="{'min-height':'600px'}">
            <Card :style="{'text-align':'center','width':'500px','margin':'80px auto'}">
                <h2>星轨-权限管理系统</h2>
                <Form ref="user" :model="user" :label-width="100" :rules="rulesUser">
                        <FormItem label="用户名" prop="account">
                            <Input v-model="user.account"/>
                        </FormItem>
                        <FormItem label="密码" prop="password">
                            <Input v-model="user.password" type="password"/>
                        </FormItem>
                        <FormItem label="邮箱验证码" prop="email_code">
                          <Input :style="{'width':'220px','margin-right':'10px','float':'left'}" v-model="user.email_code"/>
                          <Button :style="{'width':'130px','float':'right'}" type="primary" @click="sendEmailCode()" long>发送邮箱验证码</Button>
                          <p :style="{'clear':'both','text-align':'right'}">{{email_tip}}</p>
                        </FormItem>
                        <FormItem>
                            <Button type="primary" @click="loginSubmit()" long>登录</Button>
                        </FormItem>
                    </Form>
            </Card>
        </Content>
    </Layout>
</template>

<script>
export default {
    data(){
        return {
            email_tip:'',
            from:this.$route.query.from,
            user:{
                account:'',
                password:'',
                email_code:''
            },
            "rulesUser":{
                account: [
                    { required: true, message: '内容不能为空',trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '内容不能为空',trigger: 'blur' },
                ]
            }
            
        }
    },
    
    "methods":{
        loginSubmit(){
            this.$refs["user"].validate((valid) => {
                if(valid){
                    this.http.post('/api/auth/login',this.user).then(response=>{
                            let data=response.data;
                            if(data.code!=0)
                            {
                                this.$Message.error(data.message);
                                /*setTimeout(function(){
                                    window.location.reload();
                                },1500);*/
                            }
                            else{
                                // this.$store.commit('SET_ACCOUNT',this.user.account);
                                // this.$store.commit('SET_TOKEN',data.data.token);
                                // this.$store.commit('SET_ACTIONAUTH',data.data.auth_action);
                                let actionAuth=data.data.action_auth;
                                let isSuper=data.data.is_super;
                                if(isSuper==0 && (typeof(actionAuth)=='undefined' || actionAuth=={} || actionAuth==null || actionAuth==''))
                                {
                                    this.$Message.error('您没有权限登录此系统');
                                    return
                                }
                                localStorage.setItem('account',this.user.account);
                                localStorage.setItem('token',data.data.token);
                                localStorage.setItem('is_super',data.data.is_super);
                                let actionstr= JSON.stringify(data.data.action_auth);
                                localStorage.setItem('action_auth',actionstr);
                             
                            }
                            this.$router.push('/');
                        });
                } else {
                    this.$Message.error('表单验证未通过');
                }
            });
        },
        sendEmailCode(){
          this.http.post('/api/auth/sendEmailCode',this.user).then(response=>{
            let data=response.data;
            if(data.code!=0)
            {
              this.email_tip = '发送邮箱验证码失败，可点击发送重试';
              this.$Message.error(data.message);
            }
            else{
              this.email_tip = '已发送邮件至'+data.data.email;
              this.$Message.info('发送邮箱验证码成功');
            }
          });
        }
    }
    
}
</script>
