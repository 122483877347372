<template>
    <Layout>
        <Content :style="{'padding': '50px'}">
                <Breadcrumb :style="{margin: '20px 0'}">
                    <BreadcrumbItem>角色管理</BreadcrumbItem>
                </Breadcrumb>
                
                <Card>
                    <p :style="{'margin-bottom':'5px'}">
                    应用:<Select v-model="appid" style="width:200px" @on-change=getRoleList(1)>
                        <Option v-for="item in this.app_list" :value="item.id" :key="item.id">{{item.name}}</Option>
                    </Select>

                       <Button type='primary' @click="showTypeModal(0)" :style="{'margin-left':'20px'}">新增角色</Button>
                    </p>
                   <Table :columns="tableColumns" :data="role_list"></Table>
                   <Page :total="recordTotal" :current="page" :page-size="100" @on-change="getRoleList"/>
                </Card>
        </Content>
        <Modal
            v-model="showmodal"
            :footer-hide=true
            title="新建/编辑角色">
            <Form ref="role" :model="role" :label-width="80" :rules="rulesTag">
                <FormItem label="应用" prop="appid">
                    <Select v-model="role.appid" style="width:200px">
                        <Option v-for="item in this.app_list" :value="item.id" :key="item.id">{{item.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="角色名" prop="name">
                    <Input v-model="role.name"/>
                </FormItem>
                <FormItem label="超管权限" prop="is_super">
                     <i-switch v-model="role.is_super" true-value="1" false-value="0"/>
                </FormItem>
                <FormItem label="描述" prop="role_introduce">
                    <Input v-model="role.role_introduce" type="textarea"/>
                </FormItem>
             
             
                <FormItem>
                    <Button type="primary" @click="handleSubmit()">提交</Button>
                </FormItem>
            </Form>
        </Modal>
      
    </Layout>
    
</template>
<script>
export default {
    data(){
        return {
            showmodal:false,
            uploadHeader :{
              "Authorization":localStorage.getItem('token')
            },
            appid:5,
            role:{
                id:0,
                appid:'',
                name:'',
                is_super:0,
                role_introduce:'',
            },
            recordTotal:0,
            page:1,
            role_list:[],
            app_list:[],
            rulesTag:{
                    appid: [
                        { required: true, message: '应用不能为空',trigger: 'blur',type:"string" }
                    ],
                    name: [
                        { required: true, message: '角色名称不能为空',trigger: 'blur',type:"string" },
                    ],
               
            },
            tableColumns:[
                {
                    title: 'ID',
                    key: 'id'
                },
                {
                    title: '角色名',
                    key: 'name'
                },
                {
                    title: '描述',
                    key: 'role_introduce'
                },
               
               
                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Button', {
                                props: {
                                    type: 'primary',
                                    size: 'small'
                                },
                                style: {
                                        marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                       this.$router.push('/role/resource/'+params.row.id)
                                    }
                                }
                            }, '角色权限'),
                            h('Button', {
                                props: {
                                    type: 'primary',
                                    size: 'small'
                                },
                                style: {
                                        marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                       this.role.id=params.row.id;
                                       this.role.appid=params.row.appid;
                                       this.role.name=params.row.name;
                                       this.role.is_super=params.row.is_super;
                                       this.role.role_introduce=params.row.role_introduce;
                                    
                                       this.showTypeModal(1);
                                    }
                                }
                            }, '编辑'),
                           
                            h('Button', {
                                props: {
                                    type: 'error',
                                    size: 'small'
                                },
                                style: {
                                        marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                       this.delRole(params.row.id,params.index)
                                    }
                                }
                            }, '删除'),
                           
                            
                        ]);
                    }
                }
            ],
            "rows":[]
        }
    },
    mounted:function(){
        this.getAppList(1);
        this.getRoleCount();
        this.getRoleList(1);

    },
    computed:{
      getUploadUrl(){
        return process.env.VUE_APP_APIHOST+'/api/util/imgUpload';
      }
    },
    methods:{

        showTypeModal(edit){
            if(edit==0)
            {
                this.role.id='';
                this.role.appid='';
                this.role.name='';
                this.role.is_super=0;
                this.role.role_introduce='';
            }
            this.showmodal=true;
        },
        handleSubmit(){
            this.$refs["role"].validate((valid) => {
                if (valid) {
                    if(this.role.id > 0)
                    {
                        this.http.post('/api/role/updaterole',this.role).then(response=>{
                            let data=response.data;
                            if(data.code!==0)
                            {
                                this.$Message.error(data.message);
                                return false;
                            }
                            else{
                                this.showmodal=false;
                                
                                this.getRoleList(this.page);
                                return true;
                            }
                        });
                        
                    }
                    else
                    {
                        this.http.post('/api/role/createrole',this.role).then(response=>{
                            let data=response.data;
                            if(data.code!==0)
                            {
                                this.$Message.error(data.message);
                                return false;
                            }
                            else{
                                this.role.id='';
                                this.role.appid='';
                                this.role.name='';
                                this.role.is_super=0;
                                this.role.role_introduce='';
                                this.showmodal=false;
                                this.getRoleList(1);
                                return true;
                            }
                        });
                    }
                    
                } else {
                    this.$Message.error('表单验证未通过');
                }
            });
            return false;
        },
        getRoleCount(){
            this.http.get('/api/role/getrolecount',{'appid':this.appid}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.recordTotal=data.data;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        getRoleList(page=1){
            this.http.get('/api/role/getrolelist',{params:{'page':page,'appid':this.appid}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.role_list=data.data.list;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
            this.getRoleCount();
        },

        delRole(id,index){
            this.http.post('/api/role/delrole',{'id':id,}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                    return false;
                }
                this.role_list.splice(index,1);
                return true;
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        getAppList(page=1){
            this.http.get('/api/applist/getapplist',{params:{'page':page,}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.app_list=data.data.list;
                    this.appid=this.app_list[0]['id'];
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        
    }
    
}
</script>
