import axios from 'axios'
import store from '../store'
import router from '../router'
import qs from 'qs' // 引入qs

var protocol = window.location.protocol

var base_url = process.env.VUE_APP_APIHOST
if (protocol == 'https:') {
    base_url = base_url.replace('http:', 'https:')
}
// 创建axios实例
const service = axios.create({
  baseURL: base_url, // api的base_url
  timeout: 30 * 1000  // 请求超时时间
})


service.interceptors.request.use(
  config => {   
    if (localStorage.getItem('token')) {
      config.headers['Authorization'] = localStorage.getItem('token')
    }
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    if(config.method === 'post'){
      config.data = qs.stringify( {
        ...config.data //后台数据接收这块需要以表单形式提交数据，而axios中post默认的提交是json数据,所以这里选用qs模块来处理数据，也有其他处理方式，但个人觉得这个方式最简单好用
      })
    }
    // console.log('config:', config)
    return config
  },
  error => {
    // Do something with request error
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    //token 失效
    //无权操作
    if(response.data.code == 10){
      store.commit('SET_ACCOUNT','');
      store.commit('SET_TOKEN','');
      store.commit('SET_ACTIONAUTH','');
      localStorage.removeItem('account');
      localStorage.removeItem('token');
      localStorage.removeItem('action_auth');
      router.push({'path':'/login'});
    }
    else if(response.data.code == 12)
    {
      router.push({'path':'/updatepwd'});
    }
    return response
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service