<template>
    <Layout>
        <Content :style="{'padding': '50px'}">
                <Breadcrumb :style="{margin: '20px 0'}">
                    <BreadcrumbItem>用户管理</BreadcrumbItem>
                </Breadcrumb>
                
                <Card>
                    <p :style="{'margin-bottom':'5px'}">
                       <Button type='primary' @click="showTypeModal(0)" :style="{'margin-left':'20px'}">新增用户</Button>
                    </p>
                   <Table :columns="tableColumns" :data="user_list"></Table>
                   <Page :total="recordTotal" :current="page" :page-size="30" @on-change="getUserList"/>
                </Card>
        </Content>

        <Modal
            v-model="showmodal"
            title="新建/编辑用户">
            <Form ref="user" :model="user" :label-width="80" :rules="rulesTag">
                <FormItem label="用户名" prop="user_name">
                    <Input v-model="user.user_name"/>
                </FormItem>
                <FormItem label="密码" prop="password">
                    <Input v-model="user.password" type="password"/>
                </FormItem>
                <FormItem label="邮箱" prop="email">
                    <Input v-model="user.email"/>
                </FormItem>
                <FormItem label="姓名" prop="realname">
                    <Input v-model="user.realname"/>
                </FormItem>
                <FormItem label="职位" prop="job">
                    <Input v-model="user.job"/>
                </FormItem>
             
                <FormItem>
                    <Button type="primary" @click="handleSubmit()">提交</Button>
                </FormItem>
            </Form>
        </Modal>
      
    </Layout>
    
</template>
<script>
export default {
    data(){
        return {
            showmodal:false,
            showrolemodal:false,
            uploadHeader :{
              "Authorization":localStorage.getItem('token')
            },
            user:{
                id:0,
                user_name:'',
                email:'',
                realname:'',
                job:'',
                disable_status:0,
                mobile:'',
            },
            recordTotal:0,
            page:1,
            user_list:[],
            rulesTag:{
                    user_name: [
                        { required: true, message: '用户名不能为空',trigger: 'blur',type:"string" }
                    ],
                    email: [
                        { required: true, message: '邮箱不能为空',trigger: 'blur',type:"string" },
                    ],
                    realname: [
                        { required: true, message: '姓名不能为空',trigger: 'blur',type:"string" },
                    ],
            },
            tableColumns:[
                {
                    title: 'ID',
                    key: 'id'
                },
                {
                    title: '用户名',
                    key: 'user_name'
                },
                {
                    title: '邮箱',
                    key: 'email'
                },
                {
                    title: '姓名',
                    key: 'realname'
                },
                {
                  title: '职位',
                  key: 'job'
                },
                {
                    title:'状态',
                    key:'disable_status',
                    render: (h, params) => {
                        return h('span',params.row.disable_status>0 ? '禁用':'正常');
                    }
                },
               
                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Button', {
                                props: {
                                    type: 'primary',
                                    size: 'small'
                                },
                                style: {
                                        marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                       this.user.id=params.row.id;
                                       this.user.user_name=params.row.user_name;
                                       this.user.email=params.row.email;
                                       this.user.realname=params.row.realname;
                                       this.user.job=params.row.job;
                                       this.showTypeModal(1);
                                    }
                                }
                            }, '编辑'),
                             h('Button', {
                                props: {
                                    type: 'success',
                                    size: 'small'
                                },
                                style: {
                                        marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                       this.$router.push('/user/userrole/'+params.row.id)
                                    }
                                }
                            }, '分配角色'),
                            
                            h('Button', {
                                props: {
                                    type: 'warning',
                                    size: 'small'
                                },
                                style: {
                                        marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                       if(params.row.disable_status>0)
                                       {
                                           this.toggleUserStatus(params.row.id,0);
                                       }
                                       else
                                       {
                                           this.toggleUserStatus(params.row.id,1);
                                       }
                                    }
                                }
                            }, params.row.disable_status>0 ? '解禁':'禁用'),
                            h('Button', {
                                props: {
                                    type: 'error',
                                    size: 'small'
                                },
                                style: {
                                        marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                       this.delUser(params.row.id,params.index)
                                    }
                                }
                            }, '删除'),
                           
                            
                        ]);
                    }
                }
            ],
            "rows":[]
        }
    },
    mounted:function(){
        this.getUserCount();
        this.getUserList(1);
    },
    computed:{
      getUploadUrl(){
        return process.env.VUE_APP_APIHOST+'/api/util/imgUpload';
      }
    },
    methods:{

        showTypeModal(edit){
            if(edit==0)
            {
                this.user.id='';
                this.user.user_name='';
                this.user.email='';
                this.user.realname='';
                this.user.job='';
                this.user.disable_status = '';
            }
            this.showmodal=true;
        },
        handleSubmit(){
            this.$refs["user"].validate((valid) => {
                if (valid) {
                    if(this.user.id > 0)
                    {
                        this.http.post('/api/user/updateuser',this.user).then(response=>{
                            let data=response.data;
                            if(data.code!==0)
                            {
                                this.$Message.error(data.message);
                                return false;
                            }
                            else{
                                this.showmodal=false;
                                
                                this.getUserList(this.page);
                                return true;
                            }
                        });
                        
                    }
                    else
                    {
                        this.http.post('/api/user/createuser',this.user).then(response=>{
                            let data=response.data;
                            if(data.code!==0)
                            {
                                this.$Message.error(data.message);
                                return false;
                            }
                            else{
                                this.user.id='';
                                this.user.user_name='';
                                this.user.email='';
                                this.user.realname='';
                                this.user.job='';
                                this.user.disable_status = '';
                                this.showmodal=false;
                                this.getUserList(1);
                                return true;
                            }
                        });
                    }
                    
                } else {
                    this.$Message.error('表单验证未通过');
                }
            });
            return false;
        },
        getUserCount(){
            this.http.get('/api/user/getusercount',{}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.recordTotal=data.data;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        getUserList(page=1){
            this.http.get('/api/user/getuserlist',{params:{'page':page,}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.user_list=data.data.list;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
            this.getUserCount();
        },
        toggleUserStatus(id,status)
        {
            this.http.post('/api/user/updateuser',{'id':id,'disable_status':status}).then(response=>{
                            let data=response.data;
                            if(data.code!==0)
                            {
                                this.$Message.error(data.message);
                                return false;
                            }
                            else{
                                this.showmodal=false;
                                
                                this.getUserList(this.page);
                                return true;
                            }
                        });
        },
        getUser(id)
        {
            this.http.get('/api/user/getuser',{params:{'id':id,}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.user=data.data;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        delUser(id,index){
            this.http.post('/api/user/deluser',{'id':id,}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                    return false;
                }
                this.user_list.splice(index,1);
                return true;
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        }
        
    }
    
}
</script>
