<template>
    <Layout>
        <Content :style="{'padding': '50px'}">
                <Breadcrumb :style="{margin: '20px 0'}">
                    <BreadcrumbItem>资源管理</BreadcrumbItem>
                    <BreadcrumbItem>资源操作</BreadcrumbItem>
                </Breadcrumb>
                
                <Card>
                    <p :style="{'margin-bottom':'5px'}">
                       <Button type='primary' @click="showTypeModal(0)" :style="{'margin-left':'20px'}">新增资源操作</Button>
                    </p>
                   <Table :columns="tableColumns" :data="resource_action_list">
                        <template slot-scope="{ row }" slot="action">
                            {{action_names[row.action]}}
                        </template>
                   </Table>
                   <Page :total="recordTotal" :current="page" :page-size="100" @on-change="getResourceActionList"/>
                </Card>
        </Content>
        <Modal
            v-model="showmodal"
            :footer-hide=true
            title="新建/编辑资源操作">
            <Form ref="action" :model="action" :label-width="80" :rules="rulesTag">
                
                <FormItem label="名称" prop="name">
                    <Input v-model="action.name"/>
                </FormItem>
                <FormItem label="操作" prop="action">
                    <Select v-model="action.action" >
                        <Option v-for="item in this.action_list" :value="item.symbol" :key="item.id">{{item.name}}</Option>
                    </Select>
                    
                </FormItem>
                <FormItem label="URI" prop="uri">
                    <Input v-model="action.uri"/>
                </FormItem>
                <FormItem label="参数" prop="param">
                    <Input type="textarea" v-model="action.param" placeholder="请输入JSON格式,键值对，值为正则表达式"/>
                </FormItem>
             
                <FormItem>
                    <Button type="primary" @click="handleSubmit()">提交</Button>
                </FormItem>
            </Form>
        </Modal>
      
    </Layout>
    
</template>
<script>
export default {
    data(){
        return {
            showmodal:false,
            uploadHeader :{
              "Authorization":localStorage.getItem('token')
            },
            resource_id:this.$route.params.id,
            action:{
                id:0,
                resource_id:this.$route.params.id,
                name:'',
                action:'',
                uri:'',
                param:'',
            },
            recordTotal:0,
            page:1,
            action_list:[],
            action_names:[],
            resource_action_list:[],
            rulesTag:{

                    name: [
                        { required: true, message: '名称不能为空',trigger: 'blur',type:"string" },
                    ],
                     action: [
                        { required: true, message: '操作不能为空',trigger: 'blur',type:"string" },
                    ],
                   
            },
            tableColumns:[
                {
                    title: 'ID',
                    key: 'id'
                },
                {
                    title: '资源名',
                    key: 'name'
                },
                {
                    title: '操作',
                    key: 'action',
                    slot:'action'
                },
               {
                    title: 'URI',
                    key: 'uri'
                },
                {
                    title: '参数',
                    key: 'param'
                },
               
                {
                    title: '操作',
                    key: 'operate',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Button', {
                                props: {
                                    type: 'primary',
                                    size: 'small'
                                },
                                style: {
                                        marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                       this.action.id=params.row.id;
                                       this.action.resource_id=this.resource_id;
                                       this.action.name=params.row.name;
                                       this.action.action=params.row.action;
                                       this.action.uri=params.row.uri;
                                       this.action.param=params.row.param;
                                    
                                       this.showTypeModal(1);
                                    }
                                }
                            }, '编辑'),
                           
                            h('Button', {
                                props: {
                                    type: 'error',
                                    size: 'small'
                                },
                                style: {
                                        marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                       this.delResourceAction(params.row.id,params.index)
                                    }
                                }
                            }, '删除'),
                           
                            
                        ]);
                    }
                }
            ],
            "rows":[]
        }
    },
    mounted:function(){
        this.getActionList();
        this.getResourceActionCount();
        this.getResourceActionList(1);

    },
    computed:{
      getUploadUrl(){
        return process.env.VUE_APP_APIHOST+'/api/util/imgUpload';
      }
    },
    methods:{

        showTypeModal(edit){
            if(edit==0)
            {
                this.action.id='';
                this.action.name='';
                this.action.action='';
                this.action.uri='';
                this.action.param='';
            }
            this.showmodal=true;
        },
        handleSubmit(){
            this.$refs["action"].validate((valid) => {
                if (valid) {
                    if(this.action.id > 0)
                    {
                        this.http.post('/api/resource/updateresourceaction',this.action).then(response=>{
                            let data=response.data;
                            if(data.code!==0)
                            {
                                this.$Message.error(data.message);
                                return false;
                            }
                            else{
                                this.showmodal=false;
                                
                                this.getResourceActionList(this.page);
                                return true;
                            }
                        });
                        
                    }
                    else
                    {
                        this.http.post('/api/resource/createresourceaction',this.action).then(response=>{
                            let data=response.data;
                            if(data.code!==0)
                            {
                                this.$Message.error(data.message);
                                return false;
                            }
                            else{
                                this.action.id='';
                                this.action.name='';
                                this.action.action='';
                                this.action.uri='';
                                this.action.param='';
                                this.showmodal=false;
                                this.getResourceActionList(1);
                                return true;
                            }
                        });
                    }
                    
                } else {
                    this.$Message.error('表单验证未通过');
                }
            });
            return false;
        },
        getResourceActionCount(){
            this.http.get('/api/resource/getresourceactioncount',{params:{'resource_id':this.resource_id}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.recordTotal=data.data;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        getResourceActionList(page=1){
            this.http.get('/api/resource/getresourceactionlist',{params:{'page':page,'resource_id':this.resource_id}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.resource_action_list=data.data.list;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
            this.getResourceActionCount();
        },

        delResourceAction(id,index){
            this.http.post('/api/resource/delresourceaction',{'id':id,}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                    return false;
                }
                this.resource_action_list.splice(index,1);
                return true;
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
       
         getActionList(){
            this.http.get('/api/action/getactionlist',{params:{}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.action_list=data.data.list;
                    for(let i in data.data.list)
                    {
                        let item=data.data.list[i];
                        this.action_names[item['symbol']]=item['name'];
                    }
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        
    }
    
}
</script>
