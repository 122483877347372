<template>
    <Layout>
        <Content :style="{'padding': '50px'}">
                <Breadcrumb :style="{margin: '20px 0'}">
                    <BreadcrumbItem>用户管理</BreadcrumbItem>
                    <BreadcrumbItem>用户角色</BreadcrumbItem>
                </Breadcrumb>
                
                <Card>
                    <p :style="{'margin-bottom':'5px'}">
                       应用:<Select v-model="appid" style="width:200px" @on-change=onchangeAppId>
                        <Option v-for="item in this.app_list" :value="item.id" :key="item.id">{{item.name}}</Option>
                    </Select>
                    </p>
                    <br/>
                    <Checkbox-group  v-model="user_role">
                        <Checkbox v-for="itm in this.role_list" :label="itm.id" :key="itm.id">{{itm.name}}</Checkbox>
                    </Checkbox-group>
                    <br/>
                    <Button type='primary' @click="updateUserRole" :style="{'margin-left':'20px'}">提交</Button>

                   <!-- <Page :total="recordTotal" :current="page" :page-size="100" @on-change="getRoleList"/> -->
                </Card>
        </Content>

    </Layout>
    
</template>
<script>
export default {
    data(){
        return {
            showmodal:false,
            showrolemodal:false,
            uploadHeader :{
              "Authorization":localStorage.getItem('token')
            },
            
            recordTotal:0,
            page:1,
            role_list:[],
            app_list:[],
            user_role:[],
            appid:0,
            uid: this.$route.params.uid
            
        }
    },
    mounted:function(){
        this.getAppList();
    },
    computed:{
      getUploadUrl(){
        return process.env.VUE_APP_APIHOST+'/api/util/imgUpload';
      }
    },
    methods:{

        
        getRoleList(){
            this.http.get('/api/role/getallrolelist',{params:{'appid':this.appid}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.role_list=data.data.list;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
            this.getRoleCount();
        },
        getRoleCount(){
            this.http.get('/api/role/getrolecount',{params:{'appid':this.appid}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.recordTotal=data.data;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        getUserRoleList()
        {
            this.http.get('/api/role/getuserrole',{params:{'appid':this.appid,'uid':this.uid}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    console.log("data:")
                    console.log(data.data)
                    if(data.data)
                    {
                          this.user_role=data.data;
                    }
                  
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        getAppList(page=1){
            this.http.get('/api/applist/getapplist',{params:{'page':page,}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    if(data.data.list)
                    {
                        this.app_list=data.data.list;
                        this.appid=this.app_list[0]['id'];
                        this.getRoleList();
                        this.getUserRoleList();
                    }
                    
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        onchangeAppId(){
            this.getRoleList();
            this.getUserRoleList();
        },
        updateUserRole(){
           this.http.post('/api/role/updateuserrole',{appid:this.appid,uid:this.uid,roleid_list:this.user_role}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.$Message.info('更新成功');
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        }
        
    }
    
}
</script>
