<template>
    <Layout>
        <Content :style="{'padding': '50px'}">
                <Breadcrumb :style="{margin: '20px 0'}">
                    <BreadcrumbItem>操作管理</BreadcrumbItem>
                </Breadcrumb>
                
                <Card>
                    <p :style="{'margin-bottom':'5px'}">
                    

                       <Button type='primary' @click="showTypeModal(0)" :style="{'margin-left':'20px'}">新增操作</Button>
                    </p>
                   <Table :columns="tableColumns" :data="action_list"></Table>
                </Card>
        </Content>
        <Modal
            v-model="showmodal"
            :footer-hide=true
            title="新建/编辑操作">
            <Form ref="action" :model="action" :label-width="80" :rules="rulesTag">
               
                <FormItem label="操作名" prop="name">
                    <Input v-model="action.name"/>
                </FormItem>
           
                <FormItem label="标识" prop="symbol">
                    <Input v-model="action.symbol" :readonly="readonly" :disabled="readonly"/>
                </FormItem>
             
             
                <FormItem>
                    <Button type="primary" @click="handleSubmit()">提交</Button>
                </FormItem>
            </Form>
        </Modal>
      
    </Layout>
    
</template>
<script>
export default {
    data(){
        return {
            showmodal:false,
            uploadHeader :{
              "Authorization":localStorage.getItem('token')
            },
            appid:0,
            action:{
                id:0,
                name:'',
                symbol:'',
            },
            readonly:false,
            recordTotal:0,
            page:1,
            action_list:[],
            rulesTag:{

                    name: [
                        { required: true, message: '名称不能为空',trigger: 'blur',type:"string" },
                    ],
                    symbol: [
                        { required: true, message: '标识不能为空',trigger: 'blur',type:"string" },
                    ],
               
            },
            tableColumns:[
                {
                    title: 'ID',
                    key: 'id'
                },
                {
                    title: '操作名',
                    key: 'name'
                },
                {
                    title: '标识',
                    key: 'symbol'
                },
               
               
                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Button', {
                                props: {
                                    type: 'primary',
                                    size: 'small'
                                },
                                style: {
                                        marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                       this.action.id=params.row.id;
                                       this.action.name=params.row.name;
                                       this.action.symbol=params.row.symbol;                                 
                                       this.showTypeModal(1);
                                    }
                                }
                            }, '编辑'),
                     
                           
                            
                        ]);
                    }
                }
            ],
            "rows":[]
        }
    },
    mounted:function(){
        this.getActionList();

    },
    computed:{
      getUploadUrl(){
        return process.env.VUE_APP_APIHOST+'/api/util/imgUpload';
      }
    },
    methods:{

        showTypeModal(edit){
            if(edit==0)
            {
                this.action.id='';
                this.action.name='';
                this.action.symbol='';
                this.readonly=false;
            }
            else
            {
                this.readonly=true;
            }
            
            this.showmodal=true;
        },
        handleSubmit(){
            this.$refs["action"].validate((valid) => {
                if (valid) {
                    if(this.action.id > 0)
                    {
                        
                        this.http.post('/api/action/updateaction',this.action).then(response=>{
                            let data=response.data;
                            if(data.code!==0)
                            {
                                this.$Message.error(data.message);
                                return false;
                            }
                            else{
                                this.showmodal=false;
                                
                                this.getActionList();
                                return true;
                            }
                        });
                        
                    }
                    else
                    {
                        this.http.post('/api/action/createaction',this.action).then(response=>{
                            let data=response.data;
                            if(data.code!==0)
                            {
                                this.$Message.error(data.message);
                                return false;
                            }
                            else{
                                this.action.id='';
                                this.action.name='';
                                this.action.symbol='';
                                this.showmodal=false;
                                this.getActionList();
                                return true;
                            }
                        });
                    }
                    
                } else {
                    this.$Message.error('表单验证未通过');
                }
            });
            return false;
        },
       
        getActionList(){
            this.http.get('/api/action/getactionlist',{params:{}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.action_list=data.data.list;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },

        
        
    }
    
}
</script>
