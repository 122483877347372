<template>
    <Layout>
        <Content :style="{'min-height':'600px'}">
            <Card :style="{'text-align':'center','width':'500px','margin':'80px auto'}">
                <h2>更改密码</h2>
                <Form ref="user" :model="user" :label-width="100" :rules="rulesUser">
                       
                        <FormItem label="当前密码" prop="oldpassword">
                            <Input v-model="user.password.old" type="password"/>
                        </FormItem>
                        <FormItem label="新密码" prop="newpassword">
                            <Input v-model="user.password.new" type="password"/>
                            <span style="font-size:12px;color:red">密码长度大于8位且要求同时包含数字，字母大小写与字符</span>
                        </FormItem>
                        <FormItem label="确认新密码" prop="confirmpassword">
                            <Input v-model="user.password.confirm" type="password"/>
                        </FormItem>
                        <FormItem label="邮箱验证码" prop="email_code">
                          <Input :style="{'width':'220px','margin-right':'10px','float':'left'}" v-model="user.email_code"/>
                          <Button :style="{'width':'130px','float':'right'}" type="primary" @click="sendEmailCode()" long>发送邮箱验证码</Button>
                          <p :style="{'clear':'both','text-align':'right'}">{{email_tip}}</p>
                        </FormItem>
                        <FormItem>
                            <Button type="primary" @click="updatePwd()" long>登录</Button>
                        </FormItem>
                    </Form>
            </Card>
        </Content>
    </Layout>
</template>

<script>
export default {
    data(){
        return {
            email_tip:'',
            from:this.$route.query.from,
            user:{
                account:'',
                password:{
                    old:'',
                    new:'',
                    confirm:''
                },
                email_code:'',
                type:'password'
            },
            "rulesUser":{
              
            }
            
        }
    },
    
    "methods":{
        updatePwd(){
            this.$refs["user"].validate((valid) => {
                if(valid){
                    if(this.user.password.new =='')
                    {
                         this.$Message.error("密码不能为空")
                         return
                    }
                    if(this.user.password.new != this.user.password.confirm)
                    {
                         this.$Message.error("两次密码不一致")
                         return
                    }
                    this.http.post('/api/auth/updatepwd',this.user).then(response=>{
                            let data=response.data;
                            if(data.code!=0)
                            {
                                this.$Message.error(data.message);
                                return true;
                            }
                           
                            this.$router.push('/');
                        });
                } else {
                    this.$Message.error('表单验证未通过');
                }
            });
        },
        sendEmailCode(){
          this.http.post('/api/auth/sendEmailCode',this.user).then(response=>{
            let data=response.data;
            if(data.code!=0)
            {
              this.email_tip = '发送邮箱验证码失败，可点击发送重试';
              this.$Message.error(data.message);
            }
            else{
              this.email_tip = '已发送邮件至'+data.data.email;
              this.$Message.info('发送邮箱验证码成功');
            }
          });
        }
    }
    
}
</script>
