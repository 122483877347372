<template>
    <Layout>
        <Content :style="{'padding': '50px'}">
                <Breadcrumb :style="{margin: '20px 0'}">
                    <BreadcrumbItem>应用管理</BreadcrumbItem>
                </Breadcrumb>
                
                <Card>
                    <p :style="{'margin-bottom':'5px'}">
                       <Button type='primary' @click="showTypeModal(0)" :style="{'margin-left':'20px'}">新增应用</Button>
                    </p>
                   <Table :columns="tableColumns" :data="app_list"></Table>
                   <Page :total="recordTotal" :current="page" :page-size="100" @on-change="getAppList"/>
                </Card>
        </Content>
        <Modal
            v-model="showmodal"
            :footer-hide=true
            title="新建/编辑应用">
            <Form ref="app" :model="app" :label-width="80" :rules="rulesTag">
                <FormItem label="名称" prop="name">
                    <Input v-model="app.name"/>
                </FormItem>
                <FormItem label="标识" prop="symbol">
                    <Input v-model="app.symbol"/>
                </FormItem>
             
                <FormItem>
                    <Button type="primary" @click="handleSubmit()">提交</Button>
                </FormItem>
            </Form>
        </Modal>
      
    </Layout>
    
</template>
<script>
export default {
    data(){
        return {
            showmodal:false,
            uploadHeader :{
              "Authorization":localStorage.getItem('token')
            },
            app:{
                id:0,
                name:'',
                symbol:'',
            },
            recordTotal:0,
            page:1,
            app_list:[],
            rulesTag:{
                    name: [
                        { required: true, message: '名称不能为空',trigger: 'blur',type:"string" }
                    ],
                    symbol: [
                        { required: true, message: '标识不能为空',trigger: 'blur',type:"string" },
                    ],
                  
            },
            tableColumns:[
                {
                    title: 'ID',
                    key: 'id'
                },
                {
                    title: '名称',
                    key: 'name'
                },
                {
                    title: '标识',
                    key: 'symbol'
                }

            ],
            "rows":[]
        }
    },
    mounted:function(){
        this.getAppList(1);
        this.getAppCount();
    },
    computed:{
      getUploadUrl(){
        return process.env.VUE_APP_APIHOST+'/api/util/imgUpload';
      }
    },
    methods:{

        showTypeModal(edit){
            if(edit==0)
            {
                this.app.id='';
                this.app.name='';
                this.app.symbol='';

            }
            this.showmodal=true;
        },
        handleSubmit(){
            this.$refs["app"].validate((valid) => {
                if (valid) {
                    if(this.app.id > 0)
                    {
                        this.http.post('/api/applist/updateapp',this.app).then(response=>{
                            let data=response.data;
                            if(data.code!==0)
                            {
                                this.$Message.error(data.message);
                                return false;
                            }
                            else{
                                this.showmodal=false;
                                
                                this.getAppList(this.page);
                                return true;
                            }
                        });
                        
                    }
                    else
                    {
                        this.http.post('/api/applist/create',this.app).then(response=>{
                            let data=response.data;
                            if(data.code!==0)
                            {
                                this.$Message.error(data.message);
                                return false;
                            }
                            else{
                                this.app.id='';
                                this.app.name='';
                                this.app.symbol='';
                                this.showmodal=false;
                                this.getAppList(1);
                                return true;
                            }
                        });
                    }
                    
                } else {
                    this.$Message.error('表单验证未通过');
                }
            });
            return false;
        },
        getAppCount(){
            this.http.get('/api/applist/getappcount',{}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.recordTotal=data.data;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
        },
        getAppList(page=1){
            this.http.get('/api/applist/getapplist',{params:{'page':page,}}).then(response=>{
                let data=response.data;
                if(data.code!==0)
                {
                    this.$Message.error(data.message);
                }
                else{
                    this.app_list=data.data.list;
                }
            }).catch(()=>{
                this.$Message.error('网络错误');
            });
            this.getAppCount();
        },
       
        
    }
    
}
</script>
